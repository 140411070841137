<template>
    <div>

        <el-row :gutter="20">
            <!-- <el-col :span="8">
                <el-card shadow="hover" class="mgb20" style="height:252px;">
                    <div class="user-info">
                        <img src="../assets/img/img.jpg" class="user-avator" alt />
                        <div class="user-info-cont">
                            <div class="user-info-name">{{ name }}</div>
                            <div>{{ role }}</div>
                        </div>
                    </div>
                    <div class="user-info-list">
                        上次登录时间：
                        <span>2019-11-01</span>
                    </div>
                    <div class="user-info-list">
                        上次登录地点：
                        <span>东莞</span>
                    </div>
                </el-card>
                <el-card shadow="hover" style="height:252px;">
                    <template #header>
                        <div class="clearfix">
                            <span>语言详情</span>
                        </div>
                    </template>
                    Vue
                    <el-progress :percentage="71.3" color="#42b983"></el-progress>JavaScript
                    <el-progress :percentage="24.1" color="#f1e05a"></el-progress>CSS
                    <el-progress :percentage="13.7"></el-progress>HTML
                    <el-progress :percentage="5.9" color="#f56c6c"></el-progress>
                </el-card>
            </el-col> -->
            <el-col :span="24">
                <el-row :gutter="20" class="mgb20">
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{ padding: '0px' }">
                            <div class="grid-content grid-con-1">
                                <i class="el-icon-user-solid grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{stationCnt}}</div>
                                    <div>车站数量</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{ padding: '0px' }">
                            <div class="grid-content grid-con-2">
                                <i class="el-icon-message-solid grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{ledCnt}}</div>
                                    <div>LED数量</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{ padding: '0px' }">
                            <div class="grid-content grid-con-3">
                                <i class="el-icon-s-goods grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{dengxiangCnt}}</div>
                                    <div>灯箱数量</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                
                <!-- <el-card shadow="hover" style="height:403px;">
                    <template #header>
                        <div class="clearfix">
                            <span>待办事项</span>
                            <el-button style="float: right; padding: 3px 0" type="text">添加</el-button>
                        </div>
                    </template>

                    <el-table :show-header="false" :data="todoList" style="width:100%;">
                        <el-table-column width="40">
                            <template #default="scope">
                                <el-checkbox v-model="scope.row.status"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template #default="scope">
                                <div
                                    class="todo-item"
                                    :class="{
                                        'todo-item-del': scope.row.status,
                                    }"
                                >{{ scope.row.title }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column width="60">
                            <template>
                                <i class="el-icon-edit"></i>
                                <i class="el-icon-delete"></i>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card> -->
            </el-col>
        </el-row>
        <el-row style="margin-top:-20px;">
            <el-col :span="24">
                <!-- <schart ref="line0" class="schart" canvasId="line0" :options="options3"></schart> -->
                <div id="line0"  style="width:100%; height:400px"></div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card shadow="hover">
                    <schart ref="bar" class="schart" canvasId="bar" :options="options"></schart>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <schart ref="line" class="schart" canvasId="line" :options="options2"></schart>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Schart from "vue-schart";
import { mapGetters } from "vuex";
import { toRaw } from '@vue/reactivity'
var echarts = require('echarts');

export default {
    name: "dashboard",
    data() {
        return {
            stationCnt:0,
            ledCnt:0,
            dengxiangCnt:0,
            name: localStorage.getItem("ms_username"),

            options: {},
            options2: {},
            options3:{},

            lineData:[],
        };
    },
    components: {
        Schart
    },
    computed: {
        role() {
            return this.name === "admin" ? "超级管理员" : "普通用户";
        },
        ...mapGetters(["indexData","sidebar"])
    },

    async mounted() {
        // await this.$store.commit("setAnalysis_Month", {userId: 1, sDate:'2021-01-01', eDate:'2021-12-31'});
        await this.$store.dispatch('operate/indexData', {userId: 1}).then(res=>{
            // console.log('this.indexData',this.indexData[0][0])
                this.stationCnt = this.indexData[0][0].stationCnt;
                this.ledCnt = this.indexData[0][0].ledCnt;
                this.dengxiangCnt = this.indexData[0][0].dengxiangCnt;
                var ledLabels = [];
                var ledData=[];
                var dengxiangLabels = [];
                var dengxiangData = [];
                this.indexData[1].forEach(element => {
                    ledLabels.push(element.label);
                    ledData.push(element.data);
                });
                this.options = {
                    type: 'pie',
                    title: {
                        text: 'LED位置分布'
                    },
                    legend: {
                        position: 'left'
                    },
                    bgColor: '#fbfbfb',
                    labels: ledLabels,//['T恤', '牛仔裤', '连衣裙', '毛衣', '七分裤', '短裙', '羽绒服'],
                    datasets: [
                        {
                            data: ledData//[334, 278, 190, 235, 260, 200, 141]
                        }
                    ]
                };

                this.indexData[2].forEach(element => {
                    dengxiangLabels.push(element.label);
                    dengxiangData.push(element.data);
                });

                this.lineData = this.indexData[3];

                this.options2= {
                    type: 'pie',
                    title: {
                        text: '灯箱类型分布'
                    },
                    legend: {
                        position: 'left'
                    },
                    bgColor: '#fbfbfb',
                    labels: dengxiangLabels,
                    datasets: [
                        {
                            data: dengxiangData
                        }
                    ]
                };


                this.options3={
                    xAxis: {  
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: ["-", "-", "-", 934, 1290, 1330, 1320],
                        connectNulls: true,
                        lineStyle: {
                            type: "dash"
                        },
                        label:{
                            show:true,position:"top"
                        },
                        type: 'line'
                    }, 
                    {
                        data: [820, 932, 901, 934, "-", "-", "-"],
                        connectNulls: true,
                        label:{
                            show:true,position:"top"
                        },
                        type: 'line'
                    }]
                };
            }).then(()=>{
                this.initEcharts();
                this.setLineChart();
            })
        // this.obj = toRaw(this.$store.state.Analysis_Month);
        // console.log('this.lineData',this.lineData)
        
    },

    methods: {
        initEcharts() {
            var echarts = require('echarts');
            let bar = echarts.init(document.getElementById('line0'))
            // console.log('bar',bar)
            // 绘制图表
            const options1 = {
                type: 'bar',
                title: {
                    text: '最近一周各品类销售图'
                },
                bgColor: '#fbfbfb',
                labels: ['周一', '周二', '周三', '周四', '周五'],
                datasets: [
                    {
                        label: '家电',
                        fillColor: 'rgba(241, 49, 74, 0.5)',
                        data: [234, 278, 270, 190, 230]
                    },
                    {
                        label: '百货',
                        data: [164, 178, 190, 135, 160]
                    },
                    {
                        label: '食品',
                        data: [144, 198, 150, 235, 120]
                    }
                ]
            };
            bar.setOption(options1)
      },

      setLineChart(){
        const myChart = echarts.init(document.getElementById("line0"));
        const names = [
            'A级站',
            'B级站',
            '超级枢纽',
            '枢纽'
        ];
        const datasetWithFilters = [];
        const seriesList = [];
        echarts.util.each(names, function (name) {
            var datasetId = 'dataset_' + name;
            datasetWithFilters.push({
                id: datasetId,
                fromDatasetId: 'dataset_raw',
                transform: {
                    type: 'filter',
                    config: {
                    and: [
                        // { dimension: 'Ymd', gte: 1950 },
                        { dimension: 'Name', '=': name }
                    ]
                    }
                }
            });
            seriesList.push({
            type: 'line',
            datasetId: datasetId,
            showSymbol: false,
            name: name,
            endLabel: {
                show: true,
                formatter: function (params) {
                    // console.log('params',params)
                return params.seriesName; //params.value[3] + ': ' + params.value[0];
                }
            },
            labelLayout: {
                moveOverlap: 'shiftY'
            },
            emphasis: {
                focus: 'series'
            },
            encode: {
                x: 'Ymd',
                y: 'PersonCnt',
                label: ['Name', 'PersonCnt'],
                itemName: 'Ymd',
                tooltip: ['PersonCnt']
            }
            });
        });

        var option = {
            backgroundColor: '#fff',
            animationDuration: 10000,
            legend:{
                data:names,
                orient: 'vertical',  //垂直显示
                y: 'center',    //延Y轴居中
                x: 'left', //居右显示
            },
            dataset: [
            {
                id: 'dataset_raw',
                source: this.lineData
            },
            ...datasetWithFilters
            ],
            title: {
            text: '近30天不同级别车站人流量'
            },
            tooltip: {
            order: 'valueDesc',
            trigger: 'axis'
            },
            xAxis: {
            type: 'category',
            nameLocation: 'middle'
            },
            yAxis: {
            name: 'PersonCnt'
            },
            grid: {
            right: 140
            },
            series: seriesList
        };
        console.log('option',option)
        myChart.clear();
        myChart.setOption(option);
      },

      

    }
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 100px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.user-info {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
}

.user-avator {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.user-info-cont {
    padding-left: 50px;
    flex: 1;
    font-size: 14px;
    color: #999;
}

.user-info-cont div:first-child {
    font-size: 30px;
    color: #222;
}

.user-info-list {
    font-size: 14px;
    color: #999;
    line-height: 25px;
}

.user-info-list span {
    margin-left: 70px;
}

.mgb20 {
    margin-bottom: 20px;
}

.todo-item {
    font-size: 14px;
}

.todo-item-del {
    text-decoration: line-through;
    color: #999;
}

.schart {
    width: 100%;
    height: 300px;
}
</style>
